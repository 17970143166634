import React, { Component } from 'react';
import Loading from '../components/Loading';
import Illustration from '../asset/img/ForgotPassword.svg';
import PasswordChanged from '../asset/img/PasswordChanged.svg';
import EmailSent from '../asset/img/EmailSent.svg';
import { Link } from 'react-router-dom';
import AuthAPI from '../api/Auth';
import { toast } from 'react-toastify';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false,

            username: new URLSearchParams(this.props.location.search).get('u'),
            code: new URLSearchParams(this.props.location.search).get('code'),

            target: '',
            password: ['', '']
        }

        this.handleChange = this.handleChange.bind(this);
        this.confirmPassword = this.confirmPassword.bind(this);

        this.forgotPassword = this.forgotPassword.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    /* === Utility Process ====================================================== */

    handleChange(event) {
        /* Get Data by Target */
        let target = event.target.name, data = (target === 'target' ? this.state.target : this.state.password);

        if(target.includes('password')) {
            /* Change Password Data */
            if(target === 'password') {
                data[0] = event.target.value;
            }else{
                data[1] = event.target.value;
                target = 'password';
            }
        }else{
            /* Change Username Data */
            data = event.target.value;
        }

        /* Update */
        this.setState({
            [target]: data,
            isError: false
        })
    }

    confirmPassword() {
        /* Check is Password Same or Not */
        let isPass = '';

        if(this.state.password[0] === '' || this.state.password[1] === '') {
            isPass = '';
        }else if(this.state.password[0] === this.state.password[1]) {
            isPass = 'pass';
        }else{
            isPass = 'no'
        }

        return isPass;
    }

    /* === Forgot Password Protocol ============================================= */

    forgotPassword(e) {
        /* Prevent Form Default Action */
        e.preventDefault();

        this.setState({
            isLoading: true
        }, () => {
            /* Request to Email Reset Password Link */
            AuthAPI.forgotPassword(this.state.target).then((result) => {
                if(result.status === 200) {
                    /* Handle Success */
                    this.setState({
                        isLoading: false,
                        isSuccess: true
                    })
                }else{
                    /* Handle Error */
                    this.setState({
                        isLoading: false,
                        isError: true
                    })
                }
            })
        })
    }

    changePassword(e) {
        /* Prevent Form Default Action */
        e.preventDefault();

        this.setState({
            isLoading: true
        }, () => {
            /* Assign Request Body */
            let body = {
                username: this.state.username,
                password: this.state.password[0],
                code: this.state.code
            }

            /* Request to Change Password */
            AuthAPI.changePassword(body).then((result) => {
                if(result.status === 200) {
                    /* Handle Success */
                    this.setState({
                        isLoading: false,
                        isSuccess: true
                    })
                }else if(result.status === 401){
                    /* Handle Code Expired */
                    this.setState({
                        isLoading: false
                    }, () => {
                        toast.error('Kode telah kadaluarsa, silahkan ulangi proses ubah password', {
                            position: toast.POSITION.TOP_CENTER,
                            theme: 'colored'
                        });
                    })
                }else{
                    /* Handle Error */
                    this.setState({
                        isLoading: false
                    }, () => {
                        toast.error(`Password Gagal Diubah (${result.status})`, {
                            position: toast.POSITION.TOP_CENTER,
                            theme: 'colored'
                        });
                    })
                }
            })
        })
    }

    render() { 
        if(this.state.username && this.state.code) {
            if(!this.state.isSuccess) {
                return (
                    <React.Fragment>
                        <Loading show={this.state.isLoading} />
                        <div className="login-root d-flex align-items-center justify-content-center">
                            <div className="card-2fa">
                                <div className="card-2fa-body d-flex flex-column justify-content-between py-5">
                                    <div className="card-2fa-head text-center">
                                        <div className="card-2fa-title">
                                            Ganti Password Anda
                                        </div>
                                        <div className="card-2fa-subtitle">
                                            Masukkan password baru untuk merubah password akun anda.
                                        </div>
                                    </div>
                                    <form onSubmit={this.changePassword}>
                                        <input defaultValue={this.state.username} type="text" name="username" className="d-none" autoComplete="username" />
                                        <div className="mb-3">
                                            <label className="form-label fw-bold">Password Baru</label>
                                            <input onChange={this.handleChange} value={this.state.password[0]} type="password" name="password" className={"form-control form-control-sm" + (this.confirmPassword() === 'no' ? ' is-invalid' : '')} placeholder="Masukkan password" autoComplete="new-password" autoFocus minlength="8"/>
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label fw-bold">Konfirmasi Password</label>
                                            <input onChange={this.handleChange} value={this.state.password[1]} type="password" name="password_confirm" className={"form-control form-control-sm" + (this.confirmPassword() === 'no' ? ' is-invalid' : '')} placeholder="Masukkan kembali password" autoComplete="new-password" minlength="8"/>
                                            <div className="invalid-feedback">Password belum sesuai</div>
                                        </div>
                                        <button disabled={(this.confirmPassword() === 'pass') ? false : true} type="submit" className="btn btn-sm btn-submit-login">Ubah Password</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }else{
                return (
                    <React.Fragment>
                        <div className="login-root d-flex align-items-center justify-content-center">
                            <div className="card-2fa">
                                <div className="card-2fa-body d-flex flex-column justify-content-between pb-5 pt-4">
                                    <div className="card-2fa-illustration mb-2">
                                        <img src={PasswordChanged} alt="Password Changed Illustration" />
                                    </div>
                                    <div className="card-2fa-head text-center mb-4">
                                        <div className="card-2fa-title">
                                            Password Berhasil Diubah
                                        </div>
                                        <div className="card-2fa-subtitle">
                                            Password anda telah diubah, silahkan masuk dengan menggunakan password baru.
                                        </div>
                                    </div>
                                    <div className="card-2fa-footer text-center pt-2">
                                        <Link to="/login" className="back-to-app">
                                            <p><span>Masuk Dengan Akun Anda</span></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }else{
            if(!this.state.isSuccess) {
                return (
                    <React.Fragment>
                        <Loading show={this.state.isLoading} />
                        <div className="login-root d-flex align-items-center justify-content-center">
                            <div className="card-2fa">
                                <div className="card-2fa-body d-flex flex-column justify-content-between pb-5 pt-4">
                                    <div className="card-2fa-illustration mb-2">
                                        <img src={Illustration} alt="" />
                                    </div>
                                    <div className="card-2fa-head text-center">
                                        <div className="card-2fa-title">
                                            Lupa Password
                                        </div>
                                        <div className="card-2fa-subtitle">
                                            Untuk dapat merubah password akun anda, silahkan masukkan username.
                                        </div>
                                    </div>
                                    <form onSubmit={this.forgotPassword}>
                                        <div className="mb-3">
                                            <input onChange={this.handleChange} value={this.state.target} type="text" name="target" className={"text-center form-control form-control-sm" + (this.state.isError ? ' is-invalid' : '')} autoFocus />
                                            <div className="text-center invalid-feedback">
                                                Username Tidak Ditemukan
                                            </div>
                                        </div>
                                        <button disabled={this.state.target === '' ? true : false} type="submit" className="btn btn-sm btn-submit-login">Kirim</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }else{
                return (
                    <React.Fragment>
                        <div className="login-root d-flex align-items-center justify-content-center">
                            <div className="card-2fa">
                                <div className="card-2fa-body d-flex flex-column justify-content-between pb-5 pt-4">
                                    <div className="card-2fa-illustration mb-2">
                                        <img src={EmailSent} alt="Email Sent Illustration" />
                                    </div>
                                    <div className="card-2fa-head text-center mb-4">
                                        <div className="card-2fa-title">
                                            Periksa Email Anda
                                        </div>
                                        <div className="card-2fa-subtitle">
                                            Untuk dapat mengubah password, silahkan buka tautan yang telah dikirimkan ke email anda.
                                        </div>
                                    </div>
                                    <div className="card-2fa-footer text-center pt-2">
                                        <Link to="/login" className="back-to-app">
                                            <p><span>Kembali ke Halaman Login</span></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    }
}
 
export default ForgotPassword;