import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import 'bootstrap';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import './asset/style/index.scss';

import Login from './page/Login';
import Landing from './page/Landing';
import Home from './page/Home';
import Register from './page/Register';
import VerifyEmail from './page/VerifyEmail';
import ForgotPassword from './page/ForgotPassword';
import Documentation from './page/Documentation';


class App extends Component {
    render() { 
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" render={(props) => localStorage.getItem('local_session') ? <Home {...props} /> : <Landing {...props} />} />

                    <Route exact path="/login" render={(props) => <Login {...props} />} />
                    <Route exact path="/register" render={(props) => localStorage.getItem('local_session') ? <Redirect to="/" /> : <Register {...props} />} />

                    <Route exact path="/dokumentasi" render={(props) => <Documentation {...props} />} />

                    <Route exact path="/verify" render={(props) => localStorage.getItem('local_session') ? <Redirect to="/" /> : <VerifyEmail {...props} />} />
                    
                    <Route exact path="/reset-password" render={(props) => localStorage.getItem('local_session') ? <Redirect to="/" /> : <ForgotPassword {...props} />} />

                    <Route path="/" render={() => <Redirect to="/" />} />
                </Switch>
            </BrowserRouter>
        );
    }
}
 
export default App;