import React, { Component } from "react";
import Scrollspy from "react-scrollspy";

import Logo from "../asset/img/ppu-logo-signin.png";
const API_URL = process.env.REACT_APP_API;
const SSO_URL = process.env.REACT_APP_SSO_URL;
class Documentation extends Component {
  render() {
    return (
      <div className="issp-docs-root">
        <div className="docs-side">
          <div className="docs-brand">
            <div className="d-flex align-items-center">
              <img src={Logo} alt="Logo " />
              <span>Dokumentasi SSO</span>
            </div>
          </div>
          <div className="docs-sidebar">
            <Scrollspy
              items={[
                "pengantar",
                "tentang",
                "persiapan",
                "integrasi",
                "login",
                "token",
                "autentikasi",
                "logout",
              ]}
              rootEl="#docs-scrollspy-content"
              currentClassName="active"
            >
              <li className="docs-title">Pengantar</li>
              <li>
                <a href="#tentang">Tentang</a>
              </li>
              <li>
                <a href="#persiapan">Persiapan</a>
              </li>
              <li className="docs-title pt-4">Integrasi</li>
              <li>
                <a href="#login">Pengalihan Login</a>
              </li>
              <li>
                <a href="#token">Token Untuk Sesi</a>
              </li>
              <li>
                <a href="#autentikasi">Verifikasi Token</a>
              </li>
              <li>
                <a href="#logout">Logout</a>
              </li>
            </Scrollspy>
          </div>
        </div>
        <div className="docs-main" id="docs-scrollspy-content">
          <div className="docs-brand">
            <div className="d-flex align-items-center">
              <img src={Logo} alt="Logo Semarang" />
              <span>Dokumentasi SSO</span>
            </div>
          </div>
          <div className="docs-content">
            <div className="content-container mb-5" id="tentang">
              <h1>Pengantar</h1>
              <hr className="mb-4" />
              <h2>Tentang</h2>
              <p>
                Single Sign-On atau SSO Kota Semarang adalah sebuah teknologi
                yang mengizinkan pengguna untuk dapat mengakses berbagai
                aplikasi yang ada di Kota Semarang dengan hanya menggunakan satu
                akun. Dengan adanya SSO ini, diharapkan dapat memudahkan
                pengguna dalam mengakses dan mencari aplikasi yang diinginkan.
              </p>
              <p className="mb-0">
                Beberapa hal yang perlu dipahami mengenai sistem SSO ini, yaitu:
              </p>
              <ul>
                <li>
                  SSO tidak menyimpan sesi dari masing-masing pengguna. SSO
                  hanya membuat token yang selanjutnya perlu disimpan oleh
                  masing-masing aplikasi.
                </li>
                <li>
                  SSO dapat diimplementasikan pada masing-masing aplikasi
                  sebagai langkah login opsional, sehingga tidak perlu mengubah
                  atau menghilangkan proses login yang sudah ada.
                </li>
                <li>
                  SSO menyediakan pengaturan role dan hak akses, tetapi hal
                  tersebut merupakan opsional, sehingga masing-masing aplikasi
                  dapat memilih untuk tidak menggunakan pengaturan tersebut.
                </li>
              </ul>
            </div>

            <div className="content-container mb-5" id="persiapan">
              <h2>Persiapan</h2>
              <p>
                Untuk dapat melakukan integrasi, pastikan aplikasi telah
                terdaftar didalam sistem. Pendaftaran aplikasi dapat dilakukan
                dengan menghubungi{" "}
                <a
                  href="http://diskominfo.semarangkota.go.id/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dinas Komunikasi, Informatika, Statistik, dan Persandian Kota
                  Semarang
                </a>
                . Setelah aplikasi terdaftar, Anda akan mendapatkan{" "}
                <code>app_id</code> yang nantinya akan digunakan sebagai kode
                unik untuk aplikasi anda.
              </p>
            </div>

            <div className="content-container mb-5" id="login">
              <h1>Integrasi</h1>
              <hr className="mb-3" />
              <p className="mt-2 mb-4">
                Seluruh proses integrasi antara aplikasi dan SSO dapat dilakukan
                hanya dengan menggunakan REST API tanpa dibutuhkan library atau
                framework tambahan.
              </p>
              <h2>Pengalihan Login</h2>
              <p>
                Alihkan pengguna ke halaman SSO Kota Semarang untuk dapat masuk
                menggunakan akun masing-masing. Hal ini dapat dilakukan
                menggunakan hyperlink, button, atau komponen serupa lainnya.
                Adapun link pengalihan yang dituju adalah sebagai berikut:
              </p>
              <div className="api-path text-success mb-3">
                {SSO_URL}/login?app_id=
                <strong>{`{app_id}`}</strong>&redirect_uri=
                <strong>{`{redirect_uri}`}</strong>
              </div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th width="22.5%">Parameter</th>
                    <th width="12.5%">Tipe</th>
                    <th width="65%">Deskripsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>app_id</td>
                    <td>String</td>
                    <td>Kode Unik Aplikasi yang Didapatkan</td>
                  </tr>
                  <tr>
                    <td>redirect_uri</td>
                    <td>String</td>
                    <td>Target atau Tujuan Halaman Balikan yang akan Dituju</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="content-container mb-5" id="token">
              <h2>Token Untuk Sesi</h2>
              <p className="mb-2">
                Setelah proses login selesai, maka pengguna akan dialihkan
                otomatis kembali ke aplikasi, dimana aplikasi akan mendapatkan{" "}
                <code>sso_code</code>. Kode tersebut akan disertakan sebagai
                parameter untuk mendapatkan data token, refresh token, dan batas
                kadaluarsa token. Simpan data yang didapatkan dari API pada
                aplikasi.
              </p>
              <p style={{ fontWeight: 500, fontSize: "1.25rem" }}>Request</p>
              <div className="api-path text-success mb-3">
                <strong className="me-2">POST</strong>|
                {API_URL}/sess/
                <strong>{`{sso_code}`}</strong>
              </div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th width="20%">Parameter</th>
                    <th width="12.5%">Tipe</th>
                    <th width="67.5%">Deskripsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>sso_code</td>
                    <td>String</td>
                    <td>
                      Kode Unik yang Didapatkan dari SSO Setelah Proses Login
                      Berhasil
                    </td>
                  </tr>
                </tbody>
              </table>
              <p style={{ fontWeight: 500, fontSize: "1.25rem" }}>Response</p>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th width="20%">Variabel</th>
                    <th width="12.5%">Tipe</th>
                    <th width="67.5%">Deskripsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>token</td>
                    <td>String</td>
                    <td>Token Sesi Pengguna</td>
                  </tr>
                  <tr>
                    <td>refresh_token</td>
                    <td>String</td>
                    <td>Variabel Identifikasi untuk Proses Logout</td>
                  </tr>
                  <tr>
                    <td>expired_date</td>
                    <td>Date</td>
                    <td>Waktu Kadaluarsa Token</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="content-container mb-5" id="autentikasi">
              <h2 className="content-subtitle">Verifikasi Token</h2>
              <p>
                Endpoint ini digunakan sebagai validasi apakah token yang
                disimpan masih aktif atau sudah kadaluarsa. Endpoint ini juga
                dapat digunakan untuk mendapatkan data pengguna.
              </p>
              <p style={{ fontWeight: 500, fontSize: "1.25rem" }}>Request</p>
              <div className="api-path text-success mb-3">
                <strong className="me-2">POST</strong>|
                {API_URL}/auth
              </div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th width="32.5%">Header</th>
                    <th width="67.5%">Nilai</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Authorization</td>
                    <td>Bearer Token</td>
                  </tr>
                  <tr>
                    <td>Content-Type</td>
                    <td>application/json</td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th width="20%">Parameter</th>
                    <th width="12.5%">Tipe</th>
                    <th width="67.5%">Deskripsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>appId</td>
                    <td>String</td>
                    <td>Kode Unik Aplikasi yang Didapatkan</td>
                  </tr>
                </tbody>
              </table>
              <p style={{ fontWeight: 500, fontSize: "1.25rem" }}>Response</p>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th width="20%">Variabel</th>
                    <th width="12.5%">Tipe</th>
                    <th width="67.5%">Deskripsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>user</td>
                    <td>Object</td>
                    <td>Data Pengguna</td>
                  </tr>
                  <tr>
                    <td>role</td>
                    <td>Array</td>
                    <td>Role Pengguna dalam Aplikasi</td>
                  </tr>
                  <tr>
                    <td>class</td>
                    <td>Array</td>
                    <td>Akses yang Dimiliki Pengguna didalam Aplikasi</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="content-container" id="logout">
              <h2 className="content-subtitle">Logout</h2>
              <p>
                Untuk dapat melakukan proses logout, dibutuhkan{" "}
                <code>refresh_token</code> yang didapatkan ketika awal proses
                login dilakukan.
              </p>
              <p style={{ fontWeight: 500, fontSize: "1.25rem" }}>Request</p>
              <div className="api-path text-success mb-3">
                <strong className="me-2">POST</strong>|
                {API_URL}/logout
              </div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th width="32.5%">Header</th>
                    <th width="67.5%">Nilai</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Authorization</td>
                    <td>Bearer Token</td>
                  </tr>
                  <tr>
                    <td>Content-Type</td>
                    <td>application/x-www-form-urlencoded</td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th width="20%">Parameter</th>
                    <th width="12.5%">Tipe</th>
                    <th width="67.5%">Deskripsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>refresh_token</td>
                    <td>String</td>
                    <td>Refresh Token yang Didapat Pada Proses Login</td>
                  </tr>
                  <tr>
                    <td>clientId</td>
                    <td>String</td>
                    <td>Kode Unik Aplikasi yang Didapatkan</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );

    /* return (
            <div className="issp-docs-root">
                <div className="docs-side">
                    <div className="docs-brand" style={{height: '100px'}}>

                    </div>
                    <div className="docs-sidebar">
                        <Scrollspy items={['pentat', 'blue', 'green', 'pitoy', 'red', 'yellow']} rootEl="#nav-docs" currentClassName="active">
                            <li className="docs-title">
                                Pentat
                            </li>
                            <li>
                                <a href="#blue">Blue</a>
                            </li>
                            <li>
                                <a href="#green">Green</a>
                            </li>
                            <li className="docs-title pt-4">
                                Pitoy
                            </li>
                            <li>
                                <a href="#red">Red</a>
                            </li>
                            <li>
                                <a href="#yellow">Yellow</a>
                            </li>
                        </Scrollspy>
                    </div>
                </div>
                <div id="nav-docs" className="docs-main">
                    <div id="title1">
                        <h1 >pentat</h1>
                    </div>
                    <div id="blue" className="bg-primary w-100" style={{height: '500px'}}>

                    </div>
                    <div id="green" className="bg-success w-100" style={{height: '1000px'}}>

                    </div>
                    <div id="pitoy">
                        <h1 >pitoy</h1>
                    </div>
                    <div id="red" className="bg-danger w-100" style={{height: '1200px'}}>

                    </div>
                    <div id="yellow" className="bg-warning w-100" style={{height: '600px'}}>

                    </div>
                </div>
            </div>
        ); */
  }
}

export default Documentation;
