import fetchNow from './_Fetch.jsx';

const AuthAPI = {
    /* Login Protocol */
    login: (body) => {
        return fetchNow('/auth/login', 'POST', body, null, true);
    },
    relogin: (body) => {
        return fetchNow('/auth/login?type=continue', 'POST', body, null, true);
    },

    /* Verify 2FA */
    verify: (body) => {
        return fetchNow('/auth/verify', 'POST', body, null, true);
    },

    /* ============================================================================= */

    /* Get Token from SSO Code */
    getToken: (code) => {
        return fetchNow('/auth/sess/' + code, 'POST', null, null, true);
    },
    
    /* Register Account */
    register: (body) => {
        return fetchNow('/auth/signup', 'POST', body, 'JSON', true);
    },
    
    /* Verify Email */
    verifyEmail: (user, code) => {
        return fetchNow(`/auth/verify?u=${user}&code=${code}`, 'GET', null, null, true);
    },

    /* ============================================================================= */

    /* Get User Data */
    user: () => {
        return fetchNow('/auth/user', 'POST')
    },

    /* Request to Forgot Password */
    forgotPassword: (username) => {
        return fetchNow('/auth/forgot-password?u=' + username, 'POST', null, null, true);
    },

    /* Change Password */
    changePassword: (body) => {
        return fetchNow('/auth/reset-password?o=email', 'POST', body, 'JSON', true);
    },

    /* ============================================================================= */

    /* Logout Protocol */
    logout: (body) => {
        return fetchNow('/auth/logout', 'POST', body);
    }
}
 
export default AuthAPI;