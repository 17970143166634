import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import LogoSemarang from "../asset/img/ppu-logo-signin.png";
import CardDecoration from "../asset/img/Card-Decoration.svg";
import EmailSent from "../asset/img/EmailSent.svg";

import AuthAPI from "../api/Auth";
import Loading from "../components/Loading";

toast.configure();

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSuccess: false,
      isPass: "",

      data: {
        name: "",
        username: "",
        email: "",
      },

      password: ["", ""],

      error: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.register = this.register.bind(this);
    this.checkAll = this.checkAll.bind(this);
  }

  handleChange(event) {
    if (event.target.name.includes("password")) {
      let password = this.state.password;
      let isPass = this.state.isPass;

      if (event.target.name === "password") {
        password[0] = event.target.value;
      } else {
        password[1] = event.target.value;

        if (password[0] === password[1]) {
          isPass = "pass";
        } else if (password[0] === "" && password[1] === "") {
          isPass = "";
        } else {
          isPass = "no";
        }
      }

      this.setState({
        error: "",
        password,
        isPass,
      });
    } else {
      let data = this.state.data;

      data[event.target.name] = event.target.value;

      this.setState({
        error: "",
        data,
      });
    }
  }

  register(event) {
    event.preventDefault();

    this.setState(
      {
        isLoading: true,
      },
      () => {
        let request = {
          name: this.state.data.name,
          username: this.state.data.username,
          email: this.state.data.email,
          password: this.state.password[0],
        };

        AuthAPI.register(request).then((data) => {
          if (data.status === 200) {
            toast.success("Akun Berhasil Didaftarkan", {
              position: toast.POSITION.TOP_CENTER,
              theme: "colored",
            });

            this.setState({
              isSuccess: true,
            });
          } else {
            this.setState({
              isLoading: false,
              error: "NIP/Username/Email sudah terpakai",
            });
          }
        });
      }
    );
  }

  checkAll() {
    let disabled = false;

    for (let value of Object.keys(this.state.data)) {
      if (this.state.data[value] === "") {
        disabled = true;
      }
    }

    if (this.state.isPass !== "pass") {
      disabled = true;
    }

    return disabled;
  }

  componentDidMount() {
    /* Scroll to Top */
    window.scrollTo(0, 0);
  }

  render() {
    if (!this.state.isSuccess) {
      return (
        <React.Fragment>
          <Loading show={this.state.isLoading} />
          <div className="register-root d-flex align-items-center justify-content-center">
            <div className="login-card">
              <div className="row">
                <div className="login-card-header col-md-6 p-0 d-none d-md-flex flex-column align-items-center justify-content-between">
                  <div></div>
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <img
                      className="login-logo"
                      src={LogoSemarang}
                      alt="Logo Kota "
                    />
                    <h1>Single Sign On</h1>
                    <div className="subtitle"></div>
                  </div>
                  <img
                    className="login-card-decoration"
                    src={CardDecoration}
                    alt="Card Decoration"
                  />
                </div>
                <div className="login-card-body py-5 col-md-6 d-md-block d-flex flex-column justify-content-between">
                  <div className="login-card-head mb-4">
                    <div className="login-card-title">Register</div>
                    <div className="login-card-subtitle">
                      Daftar untuk mendapatkan akun publik
                    </div>
                  </div>
                  <form onSubmit={this.register}>
                    {/* <div className="mb-3">
                      <label className="form-label">NIP</label>
                      <input
                        value={this.state.data.nip}
                        onChange={this.handleChange}
                        type="tel"
                        name="nip"
                        placeholder="Masukkan NIP"
                        pattern="^[0-9]*$"
                        className={
                          "form-control form-control-sm " +
                          (this.state.error !== "" && "is-invalid")
                        }
                        autoComplete="off"
                      />
                    </div> */}
                    <div className="mb-3">
                      <label className="form-label">Nama</label>
                      <input
                        value={this.state.data.name}
                        onChange={this.handleChange}
                        type="text"
                        name="name"
                        placeholder="Masukkan nama lengkap" 
                        className={
                          "form-control form-control-sm " 
                        }
                        autoComplete="off"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Username</label>
                      <input
                        value={this.state.data.username}
                        onChange={this.handleChange}
                        type="text"
                        name="username"
                        placeholder="Masukkan username"
                        className={
                          "form-control form-control-sm " +
                          (this.state.error !== "" && "is-invalid")
                        }
                        autoComplete="off"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Email</label>
                      <input
                        value={this.state.data.email}
                        onChange={this.handleChange}
                        type="email"
                        name="email"
                        placeholder="contoh@mail.com"
                        className={
                          "form-control form-control-sm " +
                          (this.state.error !== "" && "is-invalid")
                        }
                        autoComplete="off"
                      />
                      <div className="invalid-feedback">{this.state.error}</div>
                    </div>
                    {/* <div className="mb-3">
                      <label className="form-label">Telepon</label>
                      <input
                        value={this.state.data.phone}
                        onChange={this.handleChange}
                        type="tel"
                        name="phone"
                        pattern="^[0-9]*$"
                        placeholder="62xxxxxxx"
                        minlength="9"
                        maxlength="15"
                        className={
                          "form-control form-control-sm " 
                        }
                        autoComplete="off"
                      />
                    </div> */}
                    <div className="mb-3">
                      <label className="form-label">Password</label>
                      <input
                        value={this.state.password[0]}
                        onChange={this.handleChange}
                        type="password"
                        name="password"
                        placeholder="Masukkan password"
                        minlength="8"
                        className={
                          "form-control form-control-sm " +
                          (this.state.isPass === "no" && "is-invalid")
                        }
                        autoComplete="new-password"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Konfirmasi Password</label>
                      <input
                        value={this.state.password[1]}
                        onChange={this.handleChange}
                        type="password"
                        name="password-confirm"
                        placeholder="Masukkan password kembali"
                        minlength="8"
                        className={
                          "form-control form-control-sm " +
                          (this.state.isPass === "no" && "is-invalid")
                        }
                        autoComplete="new-password"
                      />
                      <div className="invalid-feedback">
                        {this.state.isPass === "no" && "Password tidak sesuai"}
                      </div>
                    </div>
                    <button
                      disabled={this.checkAll()}
                      type="submit"
                      className="btn btn-sm btn-submit-login"
                    >
                      Daftar
                    </button>
                  </form>
                  <div className="other-action mt-4">
                    <Link to="/login" className="back-to-app">
                      <svg
                        className="feather feather-arrow-left me-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <line x1="19" y1="12" x2="5" y2="12"></line>
                        <polyline points="12 19 5 12 12 5"></polyline>
                      </svg>
                      <span>Kembali ke Halaman Login</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="login-root d-flex align-items-center justify-content-center">
            <div className="card-2fa">
              <div className="card-2fa-body d-flex flex-column justify-content-between pb-5 pt-4">
                <div className="card-2fa-illustration">
                  <img src={EmailSent} alt="Email Sent Illustration" />
                </div>
                <div className="card-2fa-head text-center mb-4">
                  <div className="card-2fa-title">
                    Akun Berhasil di Tambahkan
                  </div>
                  <div className="card-2fa-subtitle">
                    Akun Anda berhasil didaftarkan. Untuk dapat masuk, silahkan kembali ke halaman login.
                  </div>
                </div>
                <div className="card-2fa-footer text-center">
                  <Link to="/login" className="back-to-app">
                    <p>
                      <span>Kembali ke Halaman Login</span>
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default Register;
