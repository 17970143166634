import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthAPI from '../api/Auth';
import EmailVerified from '../asset/img/EmailVerified.svg';
import Loading from '../components/Loading';

class VerifyEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isSuccess: false,

            user: new URLSearchParams(this.props.location.search).get('u'),
            code: new URLSearchParams(this.props.location.search).get('code')
        }

        this.fetchNow = this.fetchNow.bind(this);
    }

    fetchNow() {
        AuthAPI.verifyEmail(this.state.user, this.state.code).then((result) => {
            if(result.status === 200) {
                this.setState({
                    isLoading: false,
                    isSuccess: true
                })
            }else{
                toast.error('Verifikasi Gagal, Silahkan Coba Beberapa Saat Lagi', {
                    position: toast.POSITION.TOP_CENTER,
                    theme: 'colored',
                    autoClose: 8000
                });

                this.setState({
                    isLoading: false,
                    isSuccess: false
                })
            }
        })
    }

    componentDidMount() {
        this.fetchNow();
    }

    render() { 
        if(this.state.user && this.state.code) {
            if(this.state.isLoading) {
                return <Loading show={this.state.isLoading} />
            }else if(!this.state.isSuccess){
                return <Redirect to="/login" />
            }else{
                return (
                    <React.Fragment>
                        <div className="login-root d-flex align-items-center justify-content-center">
                            <div className="card-2fa">
                                <div className="card-2fa-body d-flex flex-column justify-content-between pb-5 pt-4">
                                    <div className="card-2fa-illustration mb-2">
                                        <img src={EmailVerified} alt="Email Sent Illustration" />
                                    </div>
                                    <div className="card-2fa-head text-center mb-4">
                                        <div className="card-2fa-title">
                                            Verifikasi Akun Berhasil
                                        </div>
                                        <div className="card-2fa-subtitle">
                                            Akun Anda kini dapat digunakan untuk masuk. 2FA akan dilakukan saat pertama kali masuk.
                                        </div>
                                    </div>
                                    <div className="card-2fa-footer text-center pt-2">
                                        <Link to="/login" className="back-to-app">
                                            <p><span>Masuk Dengan Akun Anda</span></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
        }else{
            return <Redirect to="/login" />
        }
    }
}
 
export default VerifyEmail;